<template>
  <div class="container">
    <div class="nav">
      <div class="nav_title">请假管理</div>
      <div class="nav_left_btn" @click="toBack">
        <img :src="cancel" alt width="15" height="25" />
        <!-- <span class="back">返回</span> -->
      </div>
    </div>
    <div class="tabs">
      <div :class="mode === 0 ? 'left active' : 'left'" @click="toggleMode(0)">
        待审核
        <div class="swiper" :class="mode === 0 ? 'active-swiper' : ''"></div>
      </div>
      <div
        :class="mode === 1 ? 'right active' : 'right'"
        @click="toggleMode(1)"
      >
        已审核
        <div class="swiper" :class="mode === 1 ? 'active-swiper' : ''"></div>
      </div>
    </div>
    <div class="content common-rewrite-class">
      <div v-if="mode === 0" class="reverse">
        <van-list
          v-if="list && list.length > 0"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          :immediate-check="false"
          @load="onLoad"
        >
          <div class="reverse">
            <div v-for="(item, index) in list" :key="index" class="dish-card">
              <div class="card">
                <div class="eat-time" style="padding: 17px 16px 17px 0">
                  <div>申请人</div>
                  <div>{{ item.userName + `(${item.phone})` }}</div>
                </div>
                <div class="eat-time">
                  <div class="left">申请时间</div>
                  <div class="right">{{ item.createTime }}</div>
                </div>
                <div class="eat-time">
                  <div class="left">开始时间</div>
                  <div class="right">{{ item.startTime + ' 00:00:00' }}</div>
                </div>
                <div class="eat-time">
                  <div class="left">结束时间</div>
                  <div class="right">{{ item.endTime + ' 23:59:59' }}</div>
                </div>
                <div class="eat-time">
                  <div class="left">请假理由</div>
                  <div class="right" style="line-height: 1.5">
                    {{ item.description }}
                  </div>
                </div>
                <div class="button">
                  <div
                    style="
                      position: absolute;
                      right: 15px;
                      display: flex;
                      align-items: center;
                      box-sizing: border-box;
                      padding: 0 0 14.5px 0;
                    "
                  >
                    <div class="un-refuse" @click="openDialog(item.id, 2)">
                      拒绝
                    </div>

                    <div class="un-agree" @click="openDialog(item.id, 1)">
                      同意
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
        <van-empty v-else description="暂无数据" style="padding-top: 40%" />
      </div>
      <div v-if="mode === 1" class="reverse">
        <van-list
          v-if="examineList && examineList.length > 0"
          v-model="examineLoading"
          :finished="examineFinished"
          finished-text="没有更多了"
          :immediate-check="false"
          @load="onExamineLoad"
        >
          <div class="reverse">
            <div
              v-for="(item, index) in examineList"
              :key="index"
              class="dish-card"
            >
              <div class="card">
                <div class="eat-time">
                  <div class="left">状态</div>
                  <div :class="item.status === 1 ? 'agree' : 'refuse'">
                    {{
                      item.status === 1
                        ? '同意'
                        : item.status === 2
                        ? '拒绝'
                        : ''
                    }}
                  </div>
                </div>
                <div class="eat-time">
                  <div>申请人</div>
                  <div>
                    {{
                      item.channel === 1
                        ? item.managerName
                        : item.userName + `(${item.phone})`
                    }}
                  </div>
                </div>
                <div v-if="item.channel === 1" class="eat-time">
                  <div class="left">被申请人</div>
                  <div class="right">
                    {{ item.userName + `(${item.phone})` }}
                  </div>
                </div>
                <div class="eat-time">
                  <div class="left">申请时间</div>
                  <div class="right">{{ item.createTime }}</div>
                </div>
                <div class="eat-time">
                  <div class="left">开始时间</div>
                  <div class="right">{{ item.startTime + ' 00:00:00' }}</div>
                </div>
                <div class="eat-time">
                  <div class="left">结束时间</div>
                  <div class="right">{{ item.endTime + ' 23:59:59' }}</div>
                </div>
                <div class="eat-time" style="border-bottom: none">
                  <div class="left">请假理由</div>
                  <div class="right">{{ item.description }}</div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
        <van-empty v-else description="暂无数据" style="padding-top: 40%" />
      </div>
    </div>

    <van-dialog
      v-model="showDialog"
      show-cancel-button
      show-confirm-button
      :close-on-click-overlay="false"
      class="submit-dialog"
      confirm-button-color="#fc8888"
      cancel-button-color="#999999"
      overlay-class="overlay"
      @cancel="onCancel"
      @confirm="examineLeave"
    >
      <template #title>
        <div class="title">温馨提示</div>
      </template>
      <div class="body">{{ message }}</div>
    </van-dialog>

    <van-overlay :show="show">
      <van-loading color="#1989fa" />
    </van-overlay>
  </div>
</template>

<script>
import { getExamineList, editCwLeave } from '@/api/leave';
import cancel from '@/assets/cancel.png';

export default {
  name: 'CheckWork',
  data() {
    return {
      mode: 0,
      examineList: [],
      examinePagination: {
        current: 1,
        size: 10,
        total: 0
      },
      examineLoading: false,
      examineFinished: false,
      active: 0,
      title: '',
      show: false,
      cancel,
      list: [],
      loading: false,
      finished: false,
      pagination: {
        current: 1,
        size: 10,
        total: 0
      },
      message: '',
      showDialog: false,
      id: '',
      status: ''
    };
  },
  computed: {
    date() {
      return (day) => {
        if (day) {
          return day.substr(-8);
        } else {
          return '-';
        }
      };
    }
  },
  created() {
    const { role } = this.$store.getters;
    this.title = role === 0 ? '主管' : '班主任';
    const { pagination, examinePagination } = this;
    // const { current, size } = this.pagination
    this.fetch(pagination);
    this.examineFetch(examinePagination);
  },
  methods: {
    async toggleMode(mode) {
      this.mode = mode;
    },
    openDialog(id, status) {
      this.message = status === 2 ? '确定拒绝该申请？' : '确定同意该申请？';
      this.id = id;
      this.status = status;
      this.showDialog = true;
    },
    onCancel() {
      this.show = false;
      this.showDialog = false;
      this.id = '';
      this.status = '';
      this.message = '';
    },
    async examineLeave(id, status) {
      const { code, message } = await editCwLeave({
        id: this.id,
        status: this.status
      });
      if (code === 0) {
        this.list = [];
        this.examineList = [];
        this.pagination = {
          current: 1,
          size: 10,
          total: 0
        };
        this.examinePagination = {
          current: 1,
          size: 10,
          total: 0
        };
        await this.fetch(this.pagination);
        await this.examineFetch(this.examinePagination);
      } else {
        console.log(message);
      }
    },
    async fetch(params) {
      this.finished = false;
      const { list } = this;
      list.length === 0 && (this.show = true);
      const { code, data, message } = await getExamineList({
        ...params,
        status: 0
      });
      if (code === 0) {
        this.list = [...list, ...data.records];
        this.pagination = {
          current: data.current,
          size: data.size,
          total: data.total
        };
        setTimeout(() => {
          this.loading = false;
        }, 1000);
        this.show = false;
      } else {
        console.log(message);
      }
    },
    async examineFetch(params) {
      this.examineFinished = false;
      const { examineList } = this;
      examineList.length === 0 && (this.show = true);
      const { code, data, message } = await getExamineList({
        ...params,
        status: 1
      });
      if (code === 0) {
        console.log(examineList, 'examineList');
        this.examineList = [...examineList, ...data.records];
        this.examinePagination = {
          current: data.current,
          size: data.size,
          total: data.total
        };
        console.log(this.examineList, 'this.examineList');
        console.log(this.examinePagination, 'this.examinePagination');
        setTimeout(() => {
          this.examineLoading = false;
        }, 1000);
        this.show = false;
      } else {
        console.log(message);
      }
    },
    toHome() {
      this.$router.push({ name: 'Home' });
    },
    onLoad() {
      const { current, size, total } = this.pagination;
      if (current * size >= total) {
        this.finished = true;
      } else {
        this.fetch({ current: current + 1, size });
      }
    },
    onExamineLoad() {
      const { current, size, total } = this.examinePagination;
      console.log(current, size, total, 'current, size, total');
      if (current * size >= total) {
        this.examineFinished = true;
      } else {
        this.examineFetch({ current: current + 1, size });
      }
    },
    toBack() {
      this.$router.back();
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  background-color: #f7f7f7;
  ::v-deep .van-dialog {
    border-radius: 10px;
    font-family: 'PingFangSC-Regular';
    width: 343px;
    height: 161px;
    box-sizing: border-box;
    .body {
      margin: 24px 0;
      text-align: center;
      font-weight: 400;
      color: #666666;
      font-size: 16px;
      box-sizing: border-box;
      line-height: 16px;
    }
  }
  ::v-deep .van-dialog__header {
    padding-top: 28px;
    color: #333333;
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
  }
  .nav {
    z-index: 999;
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    text-align: center;
    height: 44px;
    color: white;
    overflow: hidden;
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 48px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 0.9rem 0.5rem 0.9rem 0.7rem;
      border-color: transparent;
      background-size: 1.2rem 2rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 0.3rem 0 0.8rem;
      top: 9px;
      left: 0px;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }

    & > .nav_left_btn:active {
      opacity: 0.5;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }
  .content {
    padding: 100px 16px 0 16px;
    .add {
      background-color: #1989fa;
      color: #fff;
      display: inline-block;
      padding: 0.4rem 2rem;
      border-radius: 3rem;
      margin-bottom: 1rem;
    }
    .reverse {
      .dish-card {
        padding-bottom: 1.5rem;
      }
    }
    .title {
      display: flex;
      align-items: center;
      //padding: 0.5rem 1.5rem .4rem;
      padding-bottom: 0.5rem;
      text-align: center;
      & > .section {
        display: flex;
        justify-content: flex-end;
        span {
          padding: 0.1rem;
          border: 1px solid #1989fa;
          color: #1989fa;
          border-radius: 50%;
          font-size: 0.875rem;
          background-color: #fff;
          margin-left: 2rem;
          width: 1.3rem;
          height: 1.3rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      div {
        width: 33.3%;
        font-weight: 600;
      }
    }
    .card {
      display: flex;
      flex-direction: column;

      box-sizing: border-box;
      background-color: #fff;
      border-radius: 10px;
      padding: 0 0 0 16px;
      box-shadow: 0 0 0.875rem #dcdcdc;
      .cancel-end {
        display: flex;
        justify-content: flex-end !important;
        padding-top: 0 !important;
      }
      & > .dishes {
        border-bottom: 1px dashed #ccc;
        .dish {
          padding: 0.5rem 0;
          justify-content: space-between;
          .common-center {
            display: flex;
            align-items: center;
          }
        }
        //display: flex;
      }
      & > .eat-time {
        position: relative;
        padding: 0px 16px 14px 0px;
        margin-top: 14px;
        //color: #07c160;
        display: flex;
        align-items: stretch; /* 让子项在交叉轴上拉伸 */
        justify-content: space-between;
        color: #333333;
        font-size: 16px;
        line-height: 16px;
        font-weight: 500;
        font-family: 'PingFangSC-Regular';
        .left {
          font-weight: 400;
          min-width: 64px;
        }
        .right {
          font-weight: 400;
          color: #666666;
          flex-grow: 1; /* 让右侧内容填充剩余空间 */
          max-width: 173px;
          text-align: right;
          word-wrap: break-word; /* 或者使用 overflow-wrap: break-word; */
        }
      }
      .eat-time:first-child {
        margin-top: 15px;
      }
      .eat-time:first-child::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: #e5e5e5;
        transform: scaleY(0.5);
        position: absolute;
        bottom: 0;
        left: 0;
      }
      .footer {
        padding-top: 0.5rem;
        //border-top: 1px dashed #ccc;
        ::v-deep .cancel {
          font-size: 0.875rem !important;
        }
        .btn {
          background-color: #ff9460;
          color: #fff;
          padding: 0.3rem 1rem;
          border-radius: 0.3rem;
          font-size: 0.875rem;
          font-weight: normal;
          display: inline-block;
        }
      }
    }
    .show-qs {
      background-color: rgba(255, 255, 255, 1);
    }
  }
}
.un-refuse {
  box-sizing: border-box;
  width: 77px;
  height: 33px;
  opacity: 1;
  border: 0.5px solid #fa5050;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #fa5050;
  font-size: 16px;
  font-family: 'PingFangSC-Regular, sans-serif';

  font-weight: 500;
  margin-right: 12px;
}
.un-agree {
  box-sizing: border-box;

  width: 77px;
  height: 33px;
  border-radius: 4px;
  opacity: 1;
  background: #fa5050;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #ffffff;
  font-size: 16px;
  font-family: 'PingFangSC-Regular, sans-serif';

  font-weight: 500;
}
.tabs {
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  margin-top: 44px;
  z-index: 99;
  .left,
  .right {
    width: 50%;
    height: 45.5px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    color: #666666;
    background: #ffffff;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .swiper {
      width: 64px;
      height: 2px;
      position: absolute;
      bottom: 0;
      border-radius: 1px;
    }
  }

  .active {
    color: #fc8888;
  }
  .active-swiper {
    background: #fc8888;
  }
}
.common-flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

::v-deep .van-tab {
  color: #607483;
  border-right: 1px solid #607483;
}
::v-deep .van-tabs__nav--card {
  border: 1px solid #cacaca !important;
}
::v-deep .van-tab.van-tab--active {
  background-color: rgba(74, 129, 196) !important;
}
.agree,
.applying,
.refuse {
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  font-family: 'PingFangSC-Regular';
  box-sizing: border-box;
  padding: 4px 8px;
}
.agree {
  background-color: #00b893;
}
.applying {
  background-color: #ffa900;
}
.refuse {
  background-color: #ed3944;
}
.overlay {
  background-color: rgba(0, 0, 0, 0.3) !important;
}
.button {
  position: relative;
  box-sizing: border-box;
  padding: 14px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
}
</style>
