import request from '@/utils/request';

export function getList(params) {
  return request({
    url: `/icbc/leave/self`,
    method: 'get',
    params
  });
}

export function getExamineList(params) {
  return request({
    url: `/icbc/leave/manager`,
    method: 'get',
    params
  });
}

export function getManager(params) {
  return request({
    url: `/icbc/leave/getManager`,
    method: 'get',
    params
  });
}

export function addLeave(data) {
  return request({
    url: `/icbc/leave`,
    method: 'post',
    data
  });
}

export function editCwLeave(data) {
  return request({
    url: `/icbc/cwLeave`,
    method: 'post',
    data
  });
}
// 获取学生名单
export function getStudentList() {
  return request({
    url: `/h5/teacher/users`,
    method: 'get'
  });
}
